import { graphql } from 'gatsby'
import React from 'react'
import BlockSale from '../components/BlockSale'
import Carousel from '../components/CarouselHeader'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import './index.scss'

const IndexPage = ({ location, data: { shopBlock, blogBlock }, pageContext: { locale } }: any) => {
  const blog = (shopGroup: any) =>
    blogBlock.group.find((blogGroup: any) => shopGroup.fieldValue === blogGroup.fieldValue) || { edges: [] }

  return (
    <Layout locale={locale} location={location}>
      <SEO location={location} locale={locale} />
      <Carousel locale={locale} />
      {shopBlock.group.map((shopGroup: any, index: number) => {
        return (
          <BlockSale
            key={index}
            locale={locale}
            products={shopGroup.edges}
            blog={blog(shopGroup).edges[0]}
            tags={[shopGroup.fieldValue]}
          />
        )
      })}
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    shopBlock: allPrismicShop(sort: { order: DESC, fields: [data___product_code, last_publication_date] }, filter: { lang: { eq: $locale } }) {
      group(field: tags, limit: 10) {
        totalCount
        edges {
          node {
            ...IndexSaleBlockFragment
          }
        }
        fieldValue
      }
    }
    blogBlock: allPrismicBlogPosts(
      sort: { order: DESC, fields: last_publication_date }
      filter: { lang: { eq: $locale } }
    ) {
      group(field: tags, limit: 1) {
        totalCount
        edges {
          node {
            ...IndexBlogBlockFragment
          }
        }
        fieldValue
      }
    }
  }
`

export default IndexPage
